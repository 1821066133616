import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSSTransition } from "react-transition-group";
import CryptoJS from "crypto-js";
import "./assetsBody.css"; // Import your custom CSS for animations
import axios from "axios";
import { getApiUrl } from "../Utilities/api";
import Cookies from "js-cookie";

const AssetsBody = ({ mobile }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false); // Add loading state
  const [filterInput, setFilterInput] = useState("");
  const [rows, setRows] = useState([]);
  const [assetVisible, setAssetVisible] = useState(false);
  const [assetEdit, setAssetEdit] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); // Track the index of the row being edited
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("Integer"); // State for the select
  const [value, setValue] = useState("");
  const [id, setId] = useState("");
  const [validated, setValidated] = useState(true);
  const prevValueRef = useRef(""); // Ref to track the previous value
  const maxRowsPerPage = 5; // Set your maximum number of rows per page
  const totalPages = Math.ceil(rows.length / maxRowsPerPage);

  const filteredRows = rows.filter(
    (row) =>
      row.name.toLowerCase().includes(filterInput.toLowerCase()) ||
      row.description.toLowerCase().includes(filterInput.toLowerCase())
  );

  const displayedRows = filteredRows.slice(
    (currentPage - 1) * maxRowsPerPage,
    currentPage * maxRowsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const encryptValue = (value) => {
    const secretKey = "your-secret-key"; // Replace with your actual secret key
    return CryptoJS.AES.encrypt(value, secretKey).toString();
  };

  const handleValueValidation = (value, prevValue) => {
    if (type === "Integer") {
      // Check if the value is an integer
      if (!Number.isInteger(Number(value))) {
        if (value.length >= prevValue.length) {
          setValidated(false);
        }
      } else {
        setValidated(true);
      }
    } else if (type === "Float") {
      // Check if the value is a valid float
      if (isNaN(value) || !/^(\d+(\.\d+)?)$/.test(value)) {
        if (value.length >= prevValue.length) {
          setValidated(false);
        }
      } else {
        setValidated(true);
      }
    } else if (type === "Secret") {
      // For "Secret", the value is always considered valid for now
      setValidated(true);
    } else if (type === "String") {
      // Optionally add any specific validation for strings if needed
      setValidated(true);
    }
  };

  const addRow = (newRow) => {
    setRows([...rows, newRow]);
  };

  const updateRow = (index, updatedRow) => {
    const updatedRows = rows.map((row, rowIndex) =>
      rowIndex === index ? updatedRow : row
    );
    setRows(updatedRows);
  };

  const deleteRow = async (index) => {
    setLoading(true);
    const token = Cookies.get("access_token");
    const newRow = rows[index];
    const rowId = newRow.id;
    try {
      const response = await axios.delete(
        getApiUrl("delete_config/") + `${rowId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": 1, // Send token in Authorization header
          },
        }
      );
      const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
      setRows(updatedRows);
      setLoading(false);
      toast.success("Asset deleted successfully");
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleAssetClose = () => {
    setAssetVisible(false);
    setAssetEdit(false);
    setEditingIndex(null); // Reset the editing index
  };

  const handleAssetOpen = () => {
    setAssetVisible(true);
  };

  const handleAssetName = (e) => {
    setName(e.target.value);
  };

  const handleAssetValue = (e) => {
    const newValue = e.target.value;
    // Validate with previous value
    prevValueRef.current = value; // Update the ref to the current value
    setValue(newValue); // Update the state
  };

  const handleAssetType = (e) => {
    const newType = e.target.value;
    setType(newType);
    // handleValueValidation(value, prevValueRef.current); // Validate with previous value
  };

  const handleAssetDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleNameLength = (name) => {
    return name.length > 0;
  };

  const handleValueLength = (value) => {
    return value.length > 0;
  };
  const handleFilter = (e) => {
    setFilterInput(e.target.value);
  };
  const handleAddRow = async () => {
    handleValueValidation(value, prevValueRef.current);
    if (!handleNameLength(name)) {
      toast.error("Name can't be empty");
    } else if (!handleValueLength(value)) {
      toast.error("Value can't be empty");
    } else if (validated) {
      const encryptedValue = type === "Secret" ? encryptValue(value) : value;
      const newRow = {
        name: name,
        description: description,
        type: type,
        value: encryptedValue,
        label: "",
        properties: "",
        id: id,
      };
      if (assetEdit) {
        setLoading(true);
        const token = Cookies.get("access_token");
        try {
          const response = await axios.put(
            getApiUrl("update_config/") + `${id}`,
            newRow,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": 1, // Send token in Authorization header
              },
            }
          );
          console.log(response);
          updateRow(editingIndex, newRow); // Update the existing row
          setName("");
          setType("Integer");
          setValue("");
          setDescription("");
          setLoading(false);
          toast.success("Asset updated successfully");
        } catch (error) {
          console.error(error);
          toast.error("Error updating the asset");
        }
      } else {
        const token = Cookies.get("access_token");
        try {
          setName("");
          setType("Integer");
          setValue("");
          setDescription("");
          setLoading(true);
          const response = await axios.post(getApiUrl("add_config"), newRow, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": 1, // Send token in Authorization header
            },
          });
          console.log(response);
          fetchData();
          setLoading(false);
          toast.success("Asset added successfully");
        } catch (error) {
          setLoading(false);
          console.error("Error adding asset!");
        }
      }
      handleAssetClose();
    } else {
      toast.error("Please add correct value");
    }
  };

  const handleEditRow = (index) => {
    const rowToEdit = rows[index];
    setName(rowToEdit.name);
    setDescription(rowToEdit.description);
    setType(rowToEdit.type);
    setValue(rowToEdit.value); // Use decrypted value if type is Secret
    setEditingIndex(index);
    setAssetVisible(true);
    setAssetEdit(true);
    setId(rowToEdit.id);
  };
  const fetchData = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await axios.get(getApiUrl("get_config"), {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": 1, // Send token in Authorization header
        },
      });
      console.log(response);
      setRows(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs only once on mount

  useEffect(() => {
    handleValueValidation(value, prevValueRef.current);
  }, [type, value]); // Run the validation when either type or value changes

  return (
    <>
      <ToastContainer />
      <CSSTransition
        in={assetVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div
          style={{
            position: "absolute",
            height: "fit-content",
            width: mobile ? "70%" : "30%",
            top: "50%",
            left: "50%",
            border: "2px solid rgb(204, 204, 204)",
            transform: "translate(-50%, -50%)",
            boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.4)",
            textAlign: "center",
            background: "white",
            color: "black",
            padding: "10px",
            zIndex: "1000",
          }}
          className="d-flex flex-column mx-3"
        >
          <h3 className="my-2">{assetEdit ? "Edit Asset" : "Add new asset"}</h3>
          <div className="d-flex justify-content-between">
            <input
              style={{
                width: "60%",
                height: "6vh",
                border: "1px solid rgb(0,0,0,0.4)",
              }}
              placeholder="Name"
              className="form-control"
              onChange={handleAssetName}
              value={name}
            />
            <select
              name="options"
              id="options"
              style={{
                borderRadius: "4px",
                width: "25%",
                border: "1px solid rgb(0,0,0,0.4)",
              }}
              value={type}
              onChange={handleAssetType}
            >
              <option value="Integer">Integer</option>
              <option value="String">String</option>
              <option value="Float">Float</option>
              <option value="Secret">Secret</option>
            </select>
          </div>
          <input
            style={{
              width: "100%",
              height: "6vh",
              border: "1px solid rgb(0,0,0,0.4)",
              boxShadow: !validated
                ? "0px 10px 10px rgba(255, 0, 0, 0.5)"
                : "none",
            }}
            placeholder="Value"
            className="my-3 form-control"
            onChange={handleAssetValue}
            value={value}
          />
          <textarea
            placeholder="Description"
            className="form-control"
            rows={5}
            style={{
              width: "100%",
              border: "1px solid rgb(0,0,0,0.4)",
              resize: "none",
            }}
            value={description}
            onChange={handleAssetDescription}
          />
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn my-3 mx-1"
              onClick={handleAddRow}
              style={{ background: "#039AC2", color: "white" }}
            >
              Save
            </button>
            <button
              className="btn btn-danger mx-1"
              onClick={handleAssetClose}
              disabled={assetEdit}
            >
              Close
            </button>
          </div>
        </div>
      </CSSTransition>

      <div
        style={{
          fontFamily: "Arial, sans-serif",
          padding: "20px",
          width: "100%",
        }}
      >
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-between align-items-center"
        >
          <p className="fs-3">
            <b>Assets</b>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              style={{
                padding: "10px",
                borderRadius: "20px",
                border: "1px solid #ddd",
                marginRight: "10px",
                width: "370px",
              }}
              value={filterInput}
              onChange={handleFilter}
              className="form-control"
            />
            <button
              className="btn"
              onClick={handleAssetOpen}
              style={{
                borderRadius: "20px",
                padding: "9px",
                background: "#039AC2",
                color: "white",
              }}
            >
              + Create New
            </button>
          </div>
        </div>
        <div
          style={{ width: "100%", textAlign: "center", borderRadius: "10px" }}
        >
          <div
            style={{
              width: "100%",
              overflowX: mobile ? "auto" : "",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",

                borderRadius: "10px",
              }}
            >
              <thead style={{ color: "#637381" }}>
                <tr style={{ backgroundColor: "#f4f4f4" }}>
                  <th style={{ padding: "10px", width: "20%" }}>Name</th>
                  <th style={{ padding: "10px", width: "40%" }}>Description</th>
                  <th style={{ padding: "10px", width: "15%" }}>Value</th>
                  <th style={{ padding: "10px", width: "15%" }}>Type</th>
                  <th style={{ padding: "10px", width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        padding: "20px",
                        textAlign: "center", // Center content horizontally
                        zIndex: "0",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block", // Center the loader within the <td>
                          border: "8px solid rgba(0, 0, 0, 0.1)", // Light border
                          borderLeft: "8px solid #000", // Darker border to create spinner effect
                          borderRadius: "50%",
                          width: "40px", // Size of the loader
                          height: "40px", // Size of the loader
                          animation: "spin 1s linear infinite",
                        }}
                      />
                    </td>
                  </tr>
                ) : rows.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        padding: "20px",
                        textAlign: "center", // Center content horizontally
                      }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          color: "#888", // Light gray text
                          opacity: "0.7", // Slightly transparent
                          zIndex: "0",
                        }}
                      >
                        No data to show
                      </p>
                    </td>
                  </tr>
                ) : (
                  displayedRows.map((row, index) => (
                    <>
                      <tr
                        key={index}
                        style={{
                          color: "#1C252E",
                          borderBottom: "1px dotted #E9ECEE",
                          width: "100%",
                        }}
                      >
                        <td
                          style={{
                            padding: "10px",

                            fontWeight: "bold",
                          }}
                        >
                          {row.name}
                        </td>
                        <td
                          style={{
                            padding: "10px",

                            fontWeight: "bold",
                          }}
                        >
                          {row.description}
                        </td>
                        <td
                          style={{
                            padding: "10px",

                            fontWeight: "bold",
                          }}
                        >
                          {row.type === "Secret" ? "********" : row.value}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            // background: "#039AC2",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              background: "rgba(3, 154, 194, 0.16)",
                              width: "fit-content",
                              borderRadius: "10px",
                              padding: "4px",
                              color: "rgba(3, 154, 194, 1)",
                            }}
                          >
                            {row.type}
                          </p>
                        </td>
                        <td
                          style={{
                            padding: "10px",

                            fontWeight: "bold",
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              handleEditRow(index);
                            }}
                            className="mx-1"
                            style={{ cursor: "pointer" }}
                          >
                            <path
                              d="M9.50018 15.126L15.6635 8.96263C14.6266 8.52969 13.6848 7.89714 12.8918 7.10096C12.0953 6.3078 11.4625 5.36577 11.0293 4.32846L4.86601 10.4918C4.38518 10.9726 4.14435 11.2135 3.93768 11.4785C3.6936 11.7912 3.48431 12.1296 3.31351 12.4876C3.16935 12.791 3.06185 13.1143 2.84685 13.7593L1.71185 17.1618C1.65961 17.3176 1.65186 17.4849 1.68947 17.6448C1.72707 17.8048 1.80855 17.951 1.92474 18.0672C2.04093 18.1834 2.18722 18.2649 2.34717 18.3025C2.50712 18.3401 2.67439 18.3324 2.83018 18.2801L6.23268 17.1451C6.87851 16.9301 7.20101 16.8226 7.50435 16.6785C7.86268 16.5076 8.20101 16.2985 8.51351 16.0543C8.77851 15.8476 9.01935 15.6068 9.50018 15.126ZM17.3735 7.25263C17.988 6.6381 18.3333 5.80462 18.3333 4.93554C18.3333 4.06647 17.988 3.23299 17.3735 2.61846C16.759 2.00393 15.9255 1.65869 15.0564 1.65869C14.1874 1.65869 13.3539 2.00393 12.7393 2.61846L12.0002 3.35763L12.0318 3.45013C12.3961 4.49239 12.9922 5.43838 13.7752 6.21679C14.5769 7.02321 15.556 7.63105 16.6343 7.99179L17.3735 7.25263Z"
                              fill="#637381"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            onClick={() => {
                              deleteRow(index);
                            }}
                            className="mx-1"
                            style={{ cursor: "pointer" }}
                          >
                            <path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z"></path>
                          </svg>
                        </td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
            </table>
            <div
              style={{
                width: "100%",
                padding: "4px",
                color: "rgb(99,115,129,0.7)",
              }}
            >
              <p style={{ width: "fit-content", marginLeft: "auto" }}>
                Page {currentPage} of {totalPages}
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.5247 23.8334C19.2726 23.8342 19.0336 23.7209 18.8747 23.525L14.8497 18.525C14.597 18.2175 14.597 17.7742 14.8497 17.4667L19.0164 12.4667C19.3109 12.1123 19.837 12.0638 20.1914 12.3584C20.5458 12.6529 20.5943 13.179 20.2997 13.5334L16.5747 18L20.1747 22.4667C20.3828 22.7165 20.4266 23.0645 20.287 23.358C20.1474 23.6516 19.8498 23.8372 19.5247 23.8334Z"
                    fill="#919EAB"
                  />
                </svg>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3328 23.8334C16.1381 23.8337 15.9494 23.7659 15.7995 23.6417C15.6291 23.5004 15.5219 23.2971 15.5016 23.0767C15.4812 22.8562 15.5495 22.6368 15.6912 22.4667L19.4245 18L15.8245 13.525C15.6847 13.3529 15.6193 13.1321 15.6428 12.9115C15.6662 12.691 15.7766 12.4889 15.9495 12.35C16.1238 12.1967 16.3542 12.123 16.5852 12.1467C16.8161 12.1704 17.0267 12.2894 17.1662 12.475L21.1912 17.475C21.4439 17.7825 21.4439 18.2259 21.1912 18.5334L17.0245 23.5334C16.855 23.7379 16.598 23.8493 16.3328 23.8334Z"
                    fill="#919EAB"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>

        <style>
          {`
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `}
        </style>
        {/* {rows.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              className="btn btn-secondary"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              {rows.length === 0 ? (
                // If there are no rows, render nothing or an empty fragment
                <></>
              ) : (
                // If there are rows, display the pagination text
                <div>
                  Page {currentPage} of {totalPages}
                </div>
              )}
            </span>
            <button
              className="btn btn-secondary"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};
export default AssetsBody;
