import axios from "axios";
import React, { useEffect, useState } from "react";
import { getApiUrl } from "../Utilities/api";
import Cookies from "js-cookie";
import DocumentViewer from "./DocumentViewer";
import { toast, ToastContainer } from "react-toastify";

function HumanValidation({ mobile }) {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFiles, setactiveFiles] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(false);
  const [keyValuePairs, setkeyValuePairs] = useState(false);
  const [tablePairs, setTablePairs] = useState(false);
  const [originalHeight, setOriginalHeight] = useState(false);
  const [originalWidth, setOriginalWidth] = useState(false);
  const [filter, setFilter] = useState("");
  const [close, setClose] = useState(false);
  const [output, setOutput] = useState(false);
  const [currentSection, setCurrentSection] = useState("upload");
  const [job_id, setJob_id] = useState("");
  const [response, setResponse] = useState("");
  const [currentScreen, setCurrentScreen] = useState("pending");
  const handlePending = () => {
    setCurrentScreen("pending");
  };
  const handleApproved = () => {
    setCurrentScreen("approved");
  };
  const fetchdata = async () => {
    const token = Cookies.get("access_token");
    const queryParams = `status=${currentScreen}`;
    try {
      const response = await axios.get(getApiUrl("hitl?" + queryParams), {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": true,
          "User-Agent": "docforensicapi", // Send token in Authorization header
        },
      });
      console.log(response);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchdata();
  }, [currentScreen]);
  const handleView = async (item) => {
    setLoading2(true);
    try {
      const token = Cookies.get("access_token");
      const response = await axios.get(
        getApiUrl("result/") + `${item.job_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": 1, // Send token in Authorization header
          },
        }
      );
      console.log("the view");
      console.log(response);
      // console.log(item.job_id);s
      if (response.data.json != null) {
        setDocumentUrl(response.data.urls.file_url);
        setkeyValuePairs(response.data.json.key_value_pairs);
        console.log(response.data.json.key_value_pairs);
        setTablePairs(response.data.json.tables);
        setOriginalHeight(response.data.json.page_data[0].height);
        setOriginalWidth(response.data.json.page_data[0].width);
        setClose(false);
        setResponse(response.data.json);
        setJob_id(item.job_id);
        toast.success("Job result fetched successfully!");
      } else {
        toast.info("the file is currently in process try again after a while!");
        console.log(response);
      }
    } catch (error) {
      toast.error("Error fetching job result!");
      console.error("Error fetching job result:", error);
    } finally {
      setLoading2(false);
    }
  };
  return (
    <>
      <ToastContainer />
      {documentUrl && keyValuePairs && !close ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "0",
            top: "0",
            background: "rgb(32, 30, 67,0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          className="Output"
        >
          <DocumentViewer
            document={documentUrl}
            keyValuePairs={keyValuePairs}
            setState={setClose}
            setDocument={setDocumentUrl}
            setKeyValue={setkeyValuePairs}
            mobile={mobile}
            tablePairs={tablePairs}
            setTablePairs={setTablePairs}
            originalImageHeightInches={originalHeight}
            originalImageWidthInches={originalWidth}
            job_id={job_id}
            response={response}
            setResponse={setResponse}
          />
        </div>
      ) : null}
      <div style={{ height: "100%", width: "100%" }}>
        <div className="d-flex align-items-center">
          <p
            className=""
            style={{ margin: "0", color: "rgba(3, 154, 194, 1)" }}
          >
            Human Validation
          </p>
          <input
            className="form-control mx-2"
            style={{ width: "27rem" }}
          ></input>
          <div
            style={{
              background:
                currentScreen === "pending"
                  ? "rgba(255, 255, 255, 1)"
                  : "rgba(3, 154, 194, 1)",
              border: "1px solid rgba(3, 154, 194, 1)",
              padding: "5px",
              color:
                currentScreen === "pending" ? "rgba(3, 154, 194, 1)" : "white",
              borderRadius: "2px",
              cursor: "pointer",
            }}
            onClick={handlePending}
          >
            Pending
          </div>
          <div
            style={{
              background:
                currentScreen === "approved"
                  ? "rgba(255, 255, 255, 1)"
                  : "rgba(3, 154, 194, 1)",
              border: "1px solid rgba(3, 154, 194, 1)",
              padding: "5px",
              color:
                currentScreen === "approved" ? "rgba(3, 154, 194, 1)" : "white",
              borderRadius: "2px",
              cursor: "pointer",
            }}
            className="mx-3"
            onClick={handleApproved}
          >
            Approved
          </div>
        </div>
        <div
          style={{
            width: "100%",
            overflowX: mobile ? "auto" : "",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "5%",
            }}
            className={mobile ? "container-sm" : ""}
          >
            <thead style={{ color: "#637381" }}>
              <tr style={{ backgroundColor: "#f4f4f4" }}>
                <th style={{ padding: "8px", textAlign: "center" }}>S.No</th>
                <th
                  style={{
                    padding: "8px",
                  }}
                >
                  Document Name
                </th>
                <th style={{ padding: "8px", textAlign: "center" }}>
                  Upload Date
                </th>
                <th style={{ padding: "8px", textAlign: "center" }}>View</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{
                      padding: "20px",
                      textAlign: "center", // Center content horizontally
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        color: "#888", // Light gray text
                        opacity: "0.7", // Slightly transparent
                        zIndex: "0",
                      }}
                    >
                      No data to show
                    </p>
                  </td>
                </tr>
              ) : (
                <>
                  {data.map((item, index) => (
                    <tr
                      key={item.id}
                      style={{
                        color: "#1C252E",
                        borderBottom: "1px dotted #E9ECEE",
                        width: "100%",
                      }}
                    >
                      <td style={{ padding: "8px", textAlign: "center" }}>
                        {/* {indexOfFirstItem + index + 1} */}
                        {index + 1}
                      </td>
                      <td style={{ padding: "8px" }}>
                        <div
                          style={{
                            maxWidth: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {/* {truncateString(item.name, maxLength)} */}
                          {item.name}
                        </div>
                      </td>
                      <td style={{ padding: "8px", textAlign: "center" }}>
                        {new Date(item.upload_datetime + "Z").toLocaleString(
                          "en-IN",
                          {
                            timeZone: "Asia/Kolkata",
                          }
                        )}
                      </td>
                      <td
                        style={{ padding: "8px", textAlign: "center" }}
                        onClick={() => handleView(item)}
                        className="text-center"
                      >
                        <button className="btn btn-primary">View</button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <div style={{ width: "100%" }}>
            <p style={{ width: "fit-content", marginLeft: "auto" }}>
              {/* Page {currentPage} of {totalPages} */}
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={
                  {
                    // pointerEvents: currentPage === 1 ? "none" : "all",
                    // cursor: currentPage === 1 ? "none" : "pointer",
                  }
                }
                //   onClick={handlePrevPage}
              >
                <path
                  d="M19.5247 23.8334C19.2726 23.8342 19.0336 23.7209 18.8747 23.525L14.8497 18.525C14.597 18.2175 14.597 17.7742 14.8497 17.4667L19.0164 12.4667C19.3109 12.1123 19.837 12.0638 20.1914 12.3584C20.5458 12.6529 20.5943 13.179 20.2997 13.5334L16.5747 18L20.1747 22.4667C20.3828 22.7165 20.4266 23.0645 20.287 23.358C20.1474 23.6516 19.8498 23.8372 19.5247 23.8334Z"
                  // fill={currentPage === 1 ? "#919EAB" : "#212B36"}
                  fill-opacity="0.8"
                />
              </svg>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={
                  {
                    // pointerEvents: currentPage !== totalPages ? "all" : "none",
                    // cursor: currentPage !== totalPages ? "pointer" : "none",
                  }
                }
                //   onClick={handleNextPage}
              >
                <path
                  d="M16.3328 23.8334C16.1381 23.8337 15.9494 23.7659 15.7995 23.6417C15.6291 23.5004 15.5219 23.2971 15.5016 23.0767C15.4812 22.8562 15.5495 22.6368 15.6912 22.4667L19.4245 18L15.8245 13.525C15.6847 13.3529 15.6193 13.1321 15.6428 12.9115C15.6662 12.691 15.7766 12.4889 15.9495 12.35C16.1238 12.1967 16.3542 12.123 16.5852 12.1467C16.8161 12.1704 17.0267 12.2894 17.1662 12.475L21.1912 17.475C21.4439 17.7825 21.4439 18.2259 21.1912 18.5334L17.0245 23.5334C16.855 23.7379 16.598 23.8493 16.3328 23.8334Z"
                  // fill={currentPage === totalPages ? "#919EAB" : "#212B36"}
                />
              </svg>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HumanValidation;
