import React, { useEffect, useState } from "react";
import { auth, provider } from "../Utilities/Config";
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../Utilities/api";
import Cookies from "js-cookie";

function LoginPage({ auth }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [value, setValue] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const BackToSignIn = () => {
    navigate(`/signin`);
  };
  const handlePasswordClick = () => {
    navigate("/password_recovery");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    // console.log(email)
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    // console.log(password)
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      const alert = document.body.getElementsByClassName("alert")[0];
      alert.setAttribute("class", "alert alert-primary alert");
      alert.innerHTML = "Please fill all the fields";
      alert.removeAttribute("hidden");
      setTimeout(() => {
        alert.setAttribute("hidden", "true");
        alert.setAttribute("class", "alert alert-primary alert");
      }, 3000);
    } else {
      e.preventDefault();
      const auth = getAuth();
      try {
        const alert = document.body.getElementsByClassName("alert")[0];
        const loader = document.body.getElementsByClassName("loader")[0];
        const mainBody = document.body.getElementsByClassName("mainPage")[0];
        mainBody.style.opacity = "0.3";
        loader.removeAttribute("hidden");
        // const userCredential = await signInWithEmailAndPassword(
        //   auth,
        //   email,
        //   password
        // );
        console.log("login");
        const bodyData = new URLSearchParams({
          username: email,
          password: password,
        });
        // const user = userCredential.user;
        // const token_id = await user.getIdToken();
        const response = await axios.post(
          getApiUrl("auth/login"),
          bodyData, // Request body
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "ngrok-skip-browser-warning": 1, // Send token in Authorization header
              // Required header
            },
          }
        );

        setStatus(response.status);
        if (response.status !== 200) {
          alert(`HTTP error! status: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = response.data;
        mainBody.style.opacity = "1";
        loader.setAttribute("hidden", "true");
        if (response.data.error) {
          alert.innerHTML = response.data.error;
          alert.removeAttribute("hidden");
          setTimeout(() => {
            alert.setAttribute("hidden", "true");
          }, 3000);
        } else {
          Cookies.set("access_token", result.access_token, { expires: 31 });
          Cookies.set("refresh_token", result.refresh_token, { expires: 31 });
        }
        console.log(result);
        navigate("/homepage");
      } catch (error) {
        const alert = document.body.getElementsByClassName("alert")[0];
        alert.setAttribute("class", "alert alert-danger alert");
        const errorMessage = error.message || "";

        // Check if the error message contains 'auth/invalid-email'
        if (
          errorMessage.includes("auth/invalid-credential") ||
          errorMessage.includes("auth/invalid-email") ||
          errorMessage.includes("auth/invalid-password")
        ) {
          // Handle the invalid email error here
          alert.innerHTML = "invalid credentials";
        } else {
          if (error.response.data.detail) {
            alert.innerHTML = error.response.data.detail;
          } else {
            alert.innerHTML = "Server error try again later";
          }
        }
        alert.removeAttribute("hidden");
        const loader = document.body.getElementsByClassName("loader")[0];
        const mainBody = document.body.getElementsByClassName("mainPage")[0];
        mainBody.style.opacity = "1";
        loader.setAttribute("hidden", "true");
        setTimeout(() => {
          alert.setAttribute("hidden", "true");
          alert.setAttribute("class", "alert alert-primary alert");
        }, 3000);
        console.log("Error Signing up: ", error);
      }
    }
  };
  const handleVerifyClick = async () => {
    localStorage.setItem("mail", email);
    try {
      const alert = document.body.getElementsByClassName("alert")[0];
      const loader = document.body.getElementsByClassName("loader")[0];
      const mainBody = document.body.getElementsByClassName("mainPage")[0];
      mainBody.style.opacity = "0.3";
      loader.removeAttribute("hidden");
      const queryParams = `email=${email}`;
      const response = await axios.post(
        getApiUrl("auth/resend-otp?" + queryParams)
      );
      console.log(response);
      mainBody.style.opacity = "1";
      loader.setAttribute("hidden", "true");
      navigate("/successfull");
    } catch (error) {
      const alert = document.body.getElementsByClassName("alert")[0];
      const loader = document.body.getElementsByClassName("loader")[0];
      const mainBody = document.body.getElementsByClassName("mainPage")[0];
      mainBody.style.opacity = "0.3";
      alert.setAttribute("class", "alert alert-danger alert");
      alert.innerHTML = error.response.data.detail;
      alert.removeAttribute("hidden");
      mainBody.style.opacity = "1";
      loader.setAttribute("hidden", "true");
      setTimeout(() => {
        alert.setAttribute("hidden", "true");
      }, 2000);
      console.log(error);
    }
  };
  // const handleClick = async () => {
  //   try {
  //     console.log("firebase");
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;
  //     const id_token = await user.getIdToken();
  //     console.log("ID Token:", id_token);
  //     const myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     // Redirect to home page after successful sign-in
  //     const bodyData = JSON.stringify({ id_token: id_token });
  //     fetch("https://api.thedocforensics.com/auth/login", {
  //       method: "POST",
  //       body: bodyData,
  //       headers: myHeaders,
  //     })
  //       .then((response) => {
  //         setStatus(response.status);
  //         if (!response.ok) {
  //           alert(`HTTP error! status: ${response.status}`);
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         console.log(response);
  //         return response.json();
  //       })
  //       .then((result) => {
  //         // console.log(result)
  //         localStorage.setItem("token", result.token);
  //         localStorage.setItem("expiry", result.expiry);
  //         const targetDate = new Date(localStorage.getItem("expiry"));
  //         const currentDate = new Date();
  //         const delay = targetDate - currentDate;
  //         console.log(delay);
  //         console.log(typeof delay);
  //         const timeoutId = setTimeout(() => {
  //           localStorage.clear();
  //           navigate("/");
  //         }, delay);
  //         window.localStorage.setItem("timeoutId", timeoutId.toString());
  //         navigate("/homepage");
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log("Error Signing up: ", error);
  //   }
  // };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/homepage");
    }
  }, []);
  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="fs-3 loader"
          style={{
            position: "absolute",
            zIndex: "1000",
          }}
          hidden
        ></div>
        <div
          className="alert alert-primary alert"
          role="alert"
          style={{ position: "absolute", top: "0", zIndex: "1000" }}
          hidden
        >
          A simple primary alert—check it out!
        </div>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          height={"200px"}
        >
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="40"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.4"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="100"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.2"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="160"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="0"
            ></animate>
          </circle>
        </svg> */}
      </div>
      <div
        className="d-flex justify-content-between align-items-center mainPage"
        style={{ height: "100vh", position: "relative" }}
      >
        <div style={{ width: "50vw", height: "100vh" }}>
          <div className="card" style={{ height: "100vh" }}>
            <div
              className="card-body d-flex justify-content-center flex-column align-items-center"
              style={{
                padding: "0",
                boxShadow: "20px 20px 20px rgba(0, 0, 0, 0.2)",
                border: "1px solid rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  height: "fit-content",
                  width: "200px",
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/logo.jpeg"
                  height={"90px"}
                ></img>
              </div>
              <h3 className="my-2">
                <b>Login To Your Account</b>
              </h3>
              <form
                className="my-2 d-flex align-items-center justify-content-center flex-column"
                style={{ width: "100%" }}
                onSubmit={handleSubmit}
              >
                <div
                  className="form-group input-container"
                  style={{ position: "relative", width: "70%" }}
                >
                  <label
                    htmlFor="email"
                    style={{
                      position: "relative",
                      top: "11px",
                      left: "10px",
                      background: "#FFFFFF",
                    }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter your email"
                    style={{
                      height: "50px",
                      border: "1px solid #6C6A6A85",
                    }}
                    value={email}
                    onChange={handleEmail}
                  />
                </div>
                <div
                  className="form-group input-container"
                  style={{ position: "relative", width: "70%" }}
                >
                  <label
                    htmlFor="message"
                    style={{
                      position: "relative",
                      top: "11px",
                      left: "10px",
                      background: "#FFFFFF",
                    }}
                  >
                    Password
                  </label>
                  <input
                    className="form-control"
                    id="message"
                    type="password"
                    placeholder="Enter your password"
                    style={{
                      height: "50px",
                      border: "1px solid #6C6A6A85",
                    }}
                    value={password}
                    onChange={handlePassword}
                  ></input>
                </div>
                <div
                  className="d-flex flex-row-reverse"
                  style={{ marginTop: "5px", width: "70%" }}
                >
                  <p
                    style={{
                      margin: "0",
                      color: "#FF4C4C",
                      marginBottom: "0",
                      cursor: "pointer",
                    }}
                    onClick={handlePasswordClick}
                  >
                    <b>Forgot Password?</b>
                  </p>
                </div>
                <div
                  className="d-flex flex-row-reverse"
                  style={{ marginTop: "5px", width: "70%" }}
                >
                  <p
                    style={{
                      margin: "0",
                      color: "#039AC2",
                      marginBottom: "0",
                      cursor: "pointer",
                    }}
                    onClick={handleVerifyClick}
                  >
                    <b>Verify Email?</b>
                  </p>
                </div>

                <div className="d-flex justify-content-center align-items-center my-2">
                  <button
                    type="submit"
                    className="btn btn-dark fs-5 mx-3"
                    style={{
                      width: "200px",
                      background: "#039AC2",
                      border: "none",
                    }}
                  >
                    <b>LOGIN</b>
                  </button>
                </div>
                <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                  <p style={{ display: "inline" }}>
                    if you haven't Registered yet?
                  </p>
                  <p
                    style={{
                      color: "#039AC2",
                      display: "inline",
                      cursor: "pointer",
                    }}
                    onClick={BackToSignIn}
                  >
                    <b> Register Now</b>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "linear-gradient(to bottom, #0b1d31, #039AC2)", // Gradient background
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            textAlign: "center",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              maxWidth: "100%",
              margin: "0 auto",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                marginBottom: "20px",
              }}
            >
              <span style={{ color: "#1bb1e7" }}>Efficient</span> Document{" "}
              <span style={{ color: "#1bb1e7" }}>Data Extraction </span>With Us
            </h1>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "1.5",
                marginBottom: "20px",
              }}
            >
              Automate and streamline your document processing. Extract data
              from various document types with accuracy and speed, reducing
              manual errors and increasing productivity.
            </p>
            <ul
              style={{
                listStyle: "none",
                padding: 0,
                margin: "0 0 20px 0",
                fontSize: "16px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <span>
                  <b>📄 Extract Data in Seconds</b> <br></br>Quickly process
                  invoices, receipts, contracts, and more.
                </span>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <span style={{ textAlign: "center" }}>
                  🔍<b className="text-center"> Accurate Data Recognition</b>
                  <br /> AI-driven technology ensures precise data extraction.
                </span>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <span>
                  <b>📊 Flexible Integration</b>
                  <br></br> Easily integrate with your existing systems.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginPage;
