import React, { useState, useRef, useEffect } from "react";
import PdfViewer from "./pdf";
import { getApiUrl } from "../Utilities/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

const DocumentViewer = ({
  document,
  keyValuePairs,
  setState,
  setKeyValue,
  mobile,
  tablePairs,
  setTablePairs,
  originalImageHeightInches,
  originalImageWidthInches,
  response,
  job_id,
  setResponse,
}) => {
  const [loading2, setLoading2] = useState(false);
  const [approved, setApproved] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [tableBoundingBox, setTableBoundingBox] = useState(false);
  const [key, setKey] = useState(false);
  const [pdfDimensions, setPdfDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [boundingBox, setBoundingBox] = useState(null);
  const imageRef = useRef(null);
  const pdfRef = useRef(null);
  const iframeContainerRef = useRef(null);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const checkIfPdf = document.toLowerCase().includes(".pdf");
    setIsPdf(checkIfPdf);
    console.log(response);
    // if (keyValuePairs.length > 0) {
    //   setSelectedKey(keyValuePairs[0].key); // Default key selected for both PDF and image
    // }
  }, [document, keyValuePairs]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (iframeContainerRef.current && pdfLoaded && boundingBox) {
  //       const boundingBoxInPixels = convertToPixels(
  //         getBoundingBox(selectedKey)
  //       );
  //       setBoundingBox(boundingBoxInPixels);
  //     }
  //   };

  //   const iframeContainer = iframeContainerRef.current;

  //   if (iframeContainer) {
  //     iframeContainer.addEventListener("scroll", handleScroll);

  //     // Cleanup the event listener
  //     return () => {
  //       iframeContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [pdfLoaded, boundingBox, selectedKey]);

  const handleImageLoad = () => {
    if (imageRef.current) {
      console.log("image is loaded");
      const { width, height } = imageRef.current;
      setImageDimensions({ width, height });
      setImageLoaded(true);
    }
  };
  // useEffect(() => {
  //   // console.log(keyValuePairs.tables);
  //   // // console.log(keyValuePairs);
  //   // console.log("here");
  //   console.log(keyValuePairs);
  //   // console.log(tablePairs);
  //   // console.log(filteredPairs);
  //   console.log(job_id);
  // }, []);
  // const handlePdfLoad = () => {
  //   console.log("loaded");
  //   if (pdfRef.current) {
  //     // Attempt to get dimensions from the embedded object (might not be accurate)
  //     const contentWidth = pdfRef.current.offsetWidth;
  //     const contentHeight = pdfRef.current.offsetHeight;

  //     setPdfDimensions({ width: contentWidth, height: contentHeight });
  //     setPdfLoaded(true);
  //   }
  // };

  const handleKeyValueClick = (key) => {
    setSelectedKey(key);
  };

  const handleCellChange = (e, tableKey, rowIndex, cellIndex) => {
    const updatedTablePairs = [...tablePairs];

    // Find the specific cell to update
    const cellToUpdate = updatedTablePairs[tableKey].cells.find(
      (cell) => cell.row_index === rowIndex && cell.column_index === cellIndex
    );

    if (cellToUpdate) {
      // Update the content of the found cell
      cellToUpdate.content = e.target.value;

      // Update the state with the modified table data
      setTablePairs(updatedTablePairs);
    } else {
      console.error("Cell not found for the given row and column indices.");
    }
  };

  const handleChange = (key, newValue) => {
    setKeyValue((prevPairs) =>
      prevPairs.map((pair) =>
        pair.key === key ? { ...pair, value: newValue } : pair
      )
    );
    console.log(keyValuePairs);
  };
  useEffect(() => {
    console.log("pairs changed");
    setResponse((prevState) => ({
      ...prevState,
      key_value_pairs: keyValuePairs,
    }));
    console.log(response);
  }, [keyValuePairs]);

  // Dimensions of the original image in inches
  // const originalImageWidthInches = 8.2639;
  // const originalImageHeightInches = 11.6806;
  const handleApprove = async () => {
    console.log("called");
    const token = Cookies.get("access_token");
    setLoading2(true);
    console.log("hey");
    try {
      const res = await axios.post(
        getApiUrl("approve/") + `${job_id}`,
        response,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": 1, // Send token in Authorization header
          },
        }
      );
      console.log(res);
      setApproved(true);
      setLoading2(false);
      toast.success("updated successfully");
    } catch (error) {
      setLoading2(false);
      toast.error("error");
    }
  };
  const dpiWidth = 1700 / originalImageWidthInches;
  const dpiHeight = 2200 / originalImageHeightInches;
  const convertToPixels = (coords) => {
    // Assuming the document/image dimensions are the same
    const width = imageDimensions.width || pdfDimensions.width;
    const height = imageDimensions.height || pdfDimensions.height;

    if (!coords || !width || !height) {
      return null;
    }

    // Maximum coordinate value
    const pixels = [];
    for (let i = 0; i < coords.length; i += 2) {
      const x = Math.round(coords[i] * dpiWidth * (width / 1700));
      const y = Math.round(coords[i + 1] * dpiHeight * (height / 2200));
      pixels.push([x, y]);
    }
    return pixels;
  };
  const calculateBoundingBox = (pixels) => {
    if (!pixels) {
      return null;
    }
    const xs = pixels.map((point) => point[0]);
    const ys = pixels.map((point) => point[1]);

    const left = Math.min(...xs);
    const top = Math.min(...ys);
    const right = Math.max(...xs);
    const bottom = Math.max(...ys);

    const width = right - left;
    const height = bottom - top;

    return { left, top, width, height };
  };

  const getBoundingBox = (key) => {
    const pair = keyValuePairs.find((p) => p.key === key);
    // console.log("yeah yeah yeah yeah yeah");
    // console.log(key);
    return pair ? pair.bounding_box : null;
  };

  // Convert bounding box to pixels
  const boundingBoxInPixels = calculateBoundingBox(
    convertToPixels(getBoundingBox(selectedKey))
  );
  const tableBoundingBoxInPixel = calculateBoundingBox(
    convertToPixels(tableBoundingBox)
  );
  const handleState = () => {
    setState(true);
  };
  const handleTableClick = (key) => {
    // console.log(key);
    setKey(key);
    // tablePairs[0];
    // console.log(key);
    console.log(key);
  };
  const handleTableClickInitial = () => {
    // console.log(key);
    setKey(0);
    // tablePairs[0];
    // console.log(key);
    console.log(key);
  };
  const handleTableValueClick = (cell) => {
    setTableBoundingBox(cell.bounding_regions[0].polygon);
    console.log(cell);
  };
  const handleTableClose = () => {
    setKey(false);
    setTableBoundingBox(false);
  };
  const filteredPairs = keyValuePairs.filter(
    (pair) => pair.page_number === pageNumber
  );
  const filteredTable = tablePairs
    ? tablePairs.filter(
        (pair) => pair.cells[0].bounding_regions[0].page_number === pageNumber
      )
    : "";
  return (
    <>
      {loading2 ? (
        <div
          style={{
            position: "absolute",
            background: "rgba(47, 54, 69, 0.8)",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            color: "white",
            zIndex: "1",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <p className="fs-3">Loading...</p>
        </div>
      ) : (
        ""
      )}
      <div style={{ display: "flex", height: "100vh", position: "relative" }}>
        <button
          className="btn btn-danger rounded-circle"
          style={{
            position: "absolute",
            top: "9px",
            right: "30px",
            zIndex: "1000",
          }}
          onClick={handleState}
        >
          {" "}
          X
        </button>

        {isPdf ? (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              flexDirection: "row", // Changed to row for PDF
              justifyContent: "space-evenly",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "relative",
                height:
                  (key === 0 || key) && filteredTable.length > 0
                    ? "50vh"
                    : "100vh",
                overflow: key === 0 || key ? "scroll" : "",
              }}
            >
              <PdfViewer
                url={document}
                setPdf={setPdfDimensions}
                setPdfLoad={setPdfLoaded}
                numPages={numPages}
                pageNumber={pageNumber}
                setNumPages={setNumPages}
                setPageNumber={setPageNumber}
              />

              {pdfLoaded && boundingBoxInPixels && (
                <div
                  style={{
                    position: "absolute",
                    pointerEvents: "none",
                    background: "rgb(55, 151, 119,0.4)",
                    top: `${boundingBoxInPixels.top}px`,
                    left: `${boundingBoxInPixels.left}px`,
                    width: `${boundingBoxInPixels.width}px`,
                    height: `${boundingBoxInPixels.height}px`,
                  }}
                />
              )}
              {pdfLoaded && tableBoundingBox && (
                <div
                  style={{
                    position: "absolute",
                    border: "0px",
                    pointerEvents: "none",
                    background: "rgb(180, 63, 63,0.4)",
                    top: `${tableBoundingBoxInPixel.top}px`,
                    left: `${tableBoundingBoxInPixel.left}px`,
                    width: `${tableBoundingBoxInPixel.width}px`,
                    height: `${tableBoundingBoxInPixel.height}px`,
                  }}
                />
              )}
            </div>
            {(key || key === 0) && filteredTable.length > 0 ? (
              <div
                style={{
                  background: "white",
                  height: "50vh",
                  position: "absolute",
                  width: "100vw",
                  bottom: "0",
                  zIndex: "1000",
                  overflowY: "auto", // Allow scrolling if the table is too large
                }}
              >
                <div style={{ width: "100%" }}>
                  <div className="d-flex">
                    <div className="card card-body flex-row">
                      {filteredTable && filteredTable.length > 0
                        ? filteredTable.map((pair, index) => (
                            <div key={index} className="table-container mx-2">
                              <button
                                style={{
                                  cursor: "pointer",
                                  background:
                                    key === index ? "#697565" : "#181C14",
                                  border: "none",
                                }}
                                onClick={() => {
                                  handleTableClick(index);
                                }}
                                className="btn btn-dark"
                              >
                                {" "}
                                <b>Table {index + 1}</b>
                              </button>
                            </div>
                          ))
                        : "No table found"}
                    </div>
                  </div>
                </div>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <button
                    className="btn btn-danger rounded-circle"
                    style={{
                      position: "absolute",
                      top: "9px",
                      right: "30px",
                      zIndex: "1000",
                    }}
                    onClick={handleTableClose}
                  >
                    X
                  </button>
                  {/* <thead> */}
                  {/* <tr> */}
                  {/* Assuming that the first row (row_index === 0) has headers */}
                  {/* {filteredTable[key].cells
      .filter((cell) => cell.row_index === 0)
      .map((cell, cellIndex) => (
        <th
          key={cellIndex}
          colSpan={cell.column_span || 1}
          rowSpan={cell.row_span || 1}
          style={{
            border: "1px solid black",
            padding: "8px",
            cursor: "pointer",
          }}
          onClick={() => {
            handleTableValueClick(cell);
          }}
        >
          {cell.content}
        </th>
      ))}
  </tr>
</thead> */}
                  <tbody>
                    {/* Create a 2D array to track covered cells */}
                    {Array.from({ length: filteredTable[key].row_count }).map(
                      (_, rowIndex) => (
                        <tr key={rowIndex}>
                          {Array.from({
                            length: filteredTable[key].column_count,
                          }).map((_, colIndex) => {
                            // Check if the current cell is covered by a rowspan or colspan
                            const isCovered = filteredTable[key].cells.some(
                              (cell) =>
                                cell.row_index <= rowIndex &&
                                rowIndex <
                                  cell.row_index + (cell.row_span || 1) &&
                                cell.column_index <= colIndex &&
                                colIndex <
                                  cell.column_index + (cell.column_span || 1) &&
                                !(
                                  cell.row_index === rowIndex &&
                                  cell.column_index === colIndex
                                )
                            );

                            if (isCovered) {
                              return null; // Skip rendering this cell
                            }

                            const cell = filteredTable[key].cells.find(
                              (c) =>
                                c.row_index === rowIndex &&
                                c.column_index === colIndex
                            );

                            return cell ? (
                              <td
                                key={colIndex}
                                colSpan={cell.column_span || 1}
                                rowSpan={cell.row_span || 1}
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleTableValueClick(cell);
                                }}
                              >
                                <input
                                  type="text"
                                  value={cell.content}
                                  onChange={(e) =>
                                    handleCellChange(
                                      e,
                                      key,
                                      rowIndex,
                                      cell.column_index
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    padding: "4px",
                                  }}
                                />
                              </td>
                            ) : (
                              <td
                                key={colIndex}
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              ></td>
                            );
                          })}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            <div style={{ width: "30%" }} className="d-flex flex-column">
              {filteredPairs.length > 0 ? (
                <div
                  style={{
                    padding: "1rem",
                    overflowY: "auto",
                    height: "50vh",
                    width: "100%",
                  }}
                >
                  {filteredPairs.map((pair, index) => (
                    <div
                      key={index}
                      onClick={() => handleKeyValueClick(pair.key)}
                      style={{
                        cursor: "pointer",
                        padding: "0.5rem",
                        backgroundColor:
                          selectedKey === pair.key ? "grey" : "transparent",
                        borderRadius: "4px",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {approved ? (
                        <div
                          style={{
                            color: "white",
                            // Green if there's a remark
                            background: pair.require_attention
                              ? "#0D7C66" // Red if require_attention is true and no remark
                              : "", // Default to no background if neither condition is true
                            // Other styles can be added here
                          }}
                        >
                          <strong style={{ color: "white" }}>
                            {pair.key}:
                          </strong>
                          <input
                            className="form-control"
                            value={pair.value}
                            onChange={(e) =>
                              handleChange(pair.key, e.target.value)
                            }
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            color: "white",
                            background: pair.remark
                              ? "#0D7C66" // Green if there's a remark
                              : pair.require_attention
                              ? "#FF8A8A" // Red if require_attention is true and no remark
                              : "", // Default to no background if neither condition is true
                            // Other styles can be added here
                          }}
                        >
                          <strong style={{ color: "white" }}>
                            {pair.key}:
                          </strong>
                          <input
                            className="form-control"
                            value={pair.value}
                            onChange={(e) =>
                              handleChange(pair.key, e.target.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  <button className="btn btn-success" onClick={handleApprove}>
                    Approve
                  </button>
                </div>
              ) : (
                <p className="fs-5 my-2" style={{ color: "white" }}>
                  No key value pairs found for this page
                </p>
              )}
              <div style={{ height: "50vh", width: "100%", padding: "10px" }}>
                {filteredTable && filteredTable.length > 0 ? (
                  <p className="d-flex gap-1 align-items-center">
                    <button
                      className="btn btn-primary"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      onClick={handleTableClickInitial}
                    >
                      Tables
                    </button>
                    <p
                      style={{ color: "white", marginBottom: "0" }}
                      className="mx-2"
                    ></p>
                  </p>
                ) : (
                  <p style={{ color: "white", padding: "5px" }}>
                    No tables found
                  </p>
                )}
                {/* <div className="collapse" id="collapseExample">
                  <div className="card card-body">
                    {filteredTable && filteredTable.length > 0
                      ? filteredTable.map((pair, index) => (
                          <div key={index} className="table-container">
                            <p
                              className="fs-4 my-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleTableClick(index);
                              }}
                            >
                              Table {index + 1}
                            </p>
                          </div>
                        ))
                      : "No table found"}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <>
            {mobile ? (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    height: "70vh",
                    overflow: "auto",
                    width: "auto",
                  }}
                >
                  <img
                    ref={imageRef}
                    src={document}
                    onLoad={handleImageLoad}
                    style={{ height: "100%", width: "100%", display: "block" }}
                    alt="Document"
                  />
                  {imageLoaded && boundingBoxInPixels && (
                    <div
                      style={{
                        position: "absolute",
                        border: "2px solid red",
                        pointerEvents: "none",
                        top: `${boundingBoxInPixels.top}px`,
                        left: `${boundingBoxInPixels.left}px`,
                        width: `${boundingBoxInPixels.width}px`,
                        height: `${boundingBoxInPixels.height}px`,
                      }}
                    />
                  )}
                  {imageLoaded && tableBoundingBox && (
                    <div
                      style={{
                        position: "absolute",
                        border: "2px solid red",
                        pointerEvents: "none",
                        top: `${tableBoundingBoxInPixel.top}px`,
                        left: `${tableBoundingBoxInPixel.left}px`,
                        width: `${tableBoundingBoxInPixel.width}px`,
                        height: `${tableBoundingBoxInPixel.height}px`,
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    padding: "1rem",
                    overflowY: "auto",
                    height: "30vh",
                    width: "100%",
                  }}
                >
                  {filteredPairs.map((pair, index) => (
                    <div
                      key={index}
                      onClick={() => handleKeyValueClick(pair.key)}
                      style={{
                        cursor: "pointer",
                        padding: "0.5rem",
                        backgroundColor:
                          selectedKey === pair.key ? "grey" : "transparent",
                        borderRadius: "4px",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <div style={{ color: "white" }}>
                        <strong style={{ color: "white" }}>{pair.key}:</strong>
                        <input
                          className="form-control"
                          value={pair.value}
                          onChange={(e) =>
                            handleChange(pair.key, e.target.value)
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <button className="btn btn-success" onClick={handleApprove}>
                    Approve
                  </button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "auto",
                    height: key === 0 || key ? "50vh" : "100vh",
                    overflow: key === 0 || key ? "scroll" : "",
                  }}
                >
                  <img
                    ref={imageRef}
                    src={document}
                    onLoad={handleImageLoad}
                    style={{
                      height: "100vh",
                      width: "100%",
                      display: "block",
                      overflow: "scroll",
                    }}
                    alt="Document"
                  />
                  {imageLoaded && boundingBoxInPixels && (
                    <div
                      style={{
                        position: "absolute",
                        border: "0px",
                        pointerEvents: "none",
                        background: "rgb(55, 151, 119,0.4)",
                        top: `${boundingBoxInPixels.top}px`,
                        left: `${boundingBoxInPixels.left}px`,
                        width: `${boundingBoxInPixels.width}px`,
                        height: `${boundingBoxInPixels.height}px`,
                      }}
                    />
                  )}
                  {imageLoaded && tableBoundingBox && (
                    <div
                      style={{
                        position: "absolute",
                        border: "0px",
                        pointerEvents: "none",
                        background: "rgb(180, 63, 63,0.4)",
                        top: `${tableBoundingBoxInPixel.top}px`,
                        left: `${tableBoundingBoxInPixel.left}px`,
                        width: `${tableBoundingBoxInPixel.width}px`,
                        height: `${tableBoundingBoxInPixel.height}px`,
                      }}
                    />
                  )}
                </div>
                {key || key === 0 ? (
                  <div
                    style={{
                      background: "white",
                      height: "50vh",
                      position: "absolute",
                      width: "100vw",
                      bottom: "0",
                      zIndex: "1000",
                      overflowY: "auto", // Allow scrolling if the table is too large
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div className="card card-body d-flex flex-row">
                        {tablePairs && tablePairs.length > 0
                          ? tablePairs.map((pair, index) => (
                              <div
                                key={index}
                                className="table-container mx-2"
                                style={{ width: "fit-content" }}
                              >
                                <button
                                  style={{
                                    cursor: "pointer",
                                    background:
                                      key === index ? "#697565" : "#181C14",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    handleTableClick(index);
                                  }}
                                  className="btn btn-dark"
                                >
                                  {" "}
                                  <b>Table {index + 1}</b>
                                </button>
                              </div>
                            ))
                          : "No table found"}
                      </div>
                    </div>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <button
                        className="btn btn-danger rounded-circle"
                        style={{
                          position: "absolute",
                          top: "9px",
                          right: "30px",
                          zIndex: "1000",
                        }}
                        onClick={handleTableClose}
                      >
                        X
                      </button>
                      {/* <thead>
  <tr>
    {/* Assuming that the first row (row_index === 0) has headers */}
                      {/* {filteredTable[key].cells
      .filter((cell) => cell.row_index === 0)
      .map((cell, cellIndex) => (
        <th
          key={cellIndex}
          colSpan={cell.column_span || 1}
          rowSpan={cell.row_span || 1}
          style={{
            border: "1px solid black",
            padding: "8px",
            cursor: "pointer",
          }}
          onClick={() => {
            handleTableValueClick(cell);
          }}
        >
          {cell.content}
        </th>
      ))}
  </tr>
</thead> */}
                      <tbody>
                        {/* Create a 2D array to track covered cells */}
                        {Array.from({
                          length: filteredTable[key].row_count,
                        }).map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            {Array.from({
                              length: filteredTable[key].column_count,
                            }).map((_, colIndex) => {
                              // Check if the current cell is covered by a rowspan or colspan
                              const isCovered = filteredTable[key].cells.some(
                                (cell) =>
                                  cell.row_index <= rowIndex &&
                                  rowIndex <
                                    cell.row_index + (cell.row_span || 1) &&
                                  cell.column_index <= colIndex &&
                                  colIndex <
                                    cell.column_index +
                                      (cell.column_span || 1) &&
                                  !(
                                    cell.row_index === rowIndex &&
                                    cell.column_index === colIndex
                                  )
                              );

                              if (isCovered) {
                                return null; // Skip rendering this cell
                              }

                              const cell = filteredTable[key].cells.find(
                                (c) =>
                                  c.row_index === rowIndex &&
                                  c.column_index === colIndex
                              );

                              return cell ? (
                                <td
                                  key={colIndex}
                                  colSpan={cell.column_span || 1}
                                  rowSpan={cell.row_span || 1}
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleTableValueClick(cell);
                                  }}
                                >
                                  <input
                                    type="text"
                                    value={cell.content}
                                    onChange={(e) =>
                                      handleCellChange(
                                        e,
                                        key,
                                        rowIndex,
                                        cell.column_index
                                      )
                                    }
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4px",
                                    }}
                                  />
                                </td>
                              ) : (
                                <td
                                  key={colIndex}
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                  }}
                                ></td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}

                <div style={{ width: "30%" }} className="d-flex flex-column ">
                  <div
                    style={{
                      padding: "1rem",
                      overflowY: "auto",
                      height: "50vh",
                      width: "100%",
                    }}
                  >
                    {filteredPairs.map((pair, index) => (
                      <div
                        key={index}
                        onClick={() => handleKeyValueClick(pair.key)}
                        style={{
                          cursor: "pointer",
                          padding: "0.5rem",
                          backgroundColor:
                            selectedKey === pair.key ? "grey" : "transparent",
                          borderRadius: "4px",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {approved ? (
                          <div
                            style={{
                              color: "white",
                              // Green if there's a remark
                              background: pair.require_attention
                                ? "#0D7C66" // Red if require_attention is true and no remark
                                : "", // Default to no background if neither condition is true
                              // Other styles can be added here
                            }}
                          >
                            <strong style={{ color: "white" }}>
                              {pair.key}:
                            </strong>
                            <input
                              className="form-control"
                              value={pair.value}
                              onChange={(e) =>
                                handleChange(pair.key, e.target.value)
                              }
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "white",
                              background: pair.remark
                                ? "#0D7C66" // Green if there's a remark
                                : pair.require_attention
                                ? "#FF8A8A" // Red if require_attention is true and no remark
                                : "", // Default to no background if neither condition is true
                              // Other styles can be added here
                            }}
                          >
                            <strong style={{ color: "white" }}>
                              {pair.key}:
                            </strong>
                            <input
                              className="form-control"
                              value={pair.value}
                              onChange={(e) =>
                                handleChange(pair.key, e.target.value)
                              }
                            />
                          </div>
                        )}
                      </div>
                    ))}
                    <button className="btn btn-success" onClick={handleApprove}>
                      Approve
                    </button>
                  </div>
                  <div
                    style={{ height: "50vh", width: "100%", padding: "10px" }}
                  >
                    {tablePairs && tablePairs.length > 0 ? (
                      <p className="d-flex gap-1 align-items-center">
                        <button
                          className="btn btn-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseExample"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          onClick={handleTableClickInitial}
                        >
                          Tables
                        </button>
                        <p
                          style={{ color: "white", marginBottom: "0" }}
                          className="mx-2"
                        ></p>
                      </p>
                    ) : (
                      <p style={{ color: "white", padding: "5px" }}>
                        No tables found
                      </p>
                    )}
                    {/* <div className="collapse" id="collapseExample">
                      <div className="card card-body">
                        {tablePairs && tablePairs.length > 0
                          ? tablePairs.map((pair, index) => (
                              <div key={index} className="table-container">
                                <p
                                  className="fs-4 my-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleTableClick(index);
                                  }}
                                >
                                  Table {index + 1}
                                </p>
                              </div>
                            ))
                          : "No table found"}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DocumentViewer;
